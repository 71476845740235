.navbar {
    display: flex;
    justify-content: center;
  }
  
  .navbar-nav {
    display: flex;
    justify-content: center;
    width: 65%;
  }
  
  .nav-link {
    padding: 0 15px;
  }

  .logo{
    position: absolute;
    top: 0%;
    left: 2%;
  }

  .custom-dropdown .dropdown-toggle::after {
    color: grey;
  }

  .custom-dropdown{
    color: white;
  }

  .username{
    color: grey;
  }
  
  .username:hover,
  .custom-dropdown .dropdown-toggle:hover::after {
    color: whitesmoke;
  }

.custom-dropdown .dropdown-toggle {
  color: #808080 !important;
}

.custom-dropdown .dropdown-toggle:hover {
  color: white !important;
}


  
  
  



  
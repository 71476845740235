.sidebar {
  display: flex;
  justify-content: flex-start; 
  position: absolute;
  left: 0;
  top: 12%;
  bottom: 0;
  width: 7%;
  height: 100%;
  background-color: #0D3A4B;
  color: #fff;
  padding-top: 0px;
  font-size: small; 
}

.nav-link-container {
  padding: 10px;
  padding-top: 20px;
  padding-right: 20px;
}

.nav-link-container .nav-link {
  color: #fff;
  text-decoration: none;
}

.nav-link-container .nav-link.active {
  color: black;
  background-color: #ffffffd8;
}

.nav-link-container .nav-link:hover {
  background-color: #ffffffd8;
}

.container {
  max-width: auto;
  max-height: 100%; 
  margin: 0 auto;
  padding: 0 12px;   
}
.accordion-collapse {
  transition: height 0.5s ease;
}






  
  /* .form-control {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 10px;
  }
  
  .form-select {
    width: 500px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .btn-primary {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
  }
  table#normsTable td {
    font-size: 12px; 
  }
 
  .table-container {
    width: 80%;
    border-collapse: collapse;
    border: 1px solid #000;
  }
  
  .table-container th {
    background-color: #007bff;
    color: #fff;
    font-size: 12px;
    border: 1px solid #000;
  }
  
  .table-container td {
    border: 1px solid #000;
  }
  
 
  .filter-container {
    margin-bottom: 20px;
  }
  
  .filter-header {
    text-align: center;
  }
  
  .filter-dropdown {
    margin: 0 auto;
  }
  
  .filter-dropdown button {
    margin-left: 8px;
  }
  
  
  .modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
  }
  
  .modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
  }
  
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  } */
